$theme-colors: (
    "success": #92d050
);
$font-size-base: 1.1rem;
$font-family-sans-serif: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;

$headings-font-weight: 700;

@import "~bootstrap/scss/bootstrap";
@import "./actiontext.scss";

#footer {
    @extend .text-body;
    a {
        color:inherit;
        font-weight: bold;
    }
    
    li {
        margin-bottom: 5px;
    }
    .footer-notice {
        font-size: 14px;
    }
}

.clickable {
    cursor: pointer;
}
.toastui-editor-contents {
    font-size: 1rem;
    h1, .h1 { @include font-size($h1-font-size); }
    h2, .h2 { @include font-size($h2-font-size); }
    h3, .h3 { @include font-size($h3-font-size); }
    h4, .h4 { @include font-size($h4-font-size); }
    h5, .h5 { @include font-size($h5-font-size); }
    h6, .h6 { @include font-size($h6-font-size); }

}


.markdown {
    table {
        @extend .table,.table-bordered;
    }
    word-break: break-all;
    img {
        max-width: 100%;
    }
}

.copyable-ip {
    cursor: pointer;
}
.box {
    margin-top: 10px;
    width: 100%;
}

.box .box-header {
    background-color: #92d050;
    padding: 15px;
    color: #fff;
        font-weight: bold;
    //border-radius: 5px 5px 0 0;
}

@keyframes blink-lightcoral {
    50% {
        background-color: lightcoral;
    }
}

.box .box-header-error {
    @extend .box-header;
    animation: blink-lightcoral 2s infinite;
}
.mc-add-server-button {
    padding: 15px;
    padding-left: 17px;
    margin: -15px;
    background-color: #428bca;
    color: #fff;
    //border-radius: 0 5px 0 0;
    border: 0;
    font-size: 17px;
}
.mc-btn {

}
.mc-btn:hover {
    color: #ffffff;
    background-color: #3071a9;
}

.mc-btn:focus, .mc-btn:active {
    text-decoration: none;
    color: #ffffff;
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.box .box-content {
    background-color: #fff;
    border: 1px solid #DDDDDD;
    //border-radius: 0 0 5px 5px;
}

.mc-server {
    word-break: keep-all;
}

.mc-server:hover {
    background-color: #f5f5f5;
    transition-property: background-color;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    /*color: #FFC0CB;*/
}

.servers-pagenation {
    @extend .py-3;
}

.server-info-wrap {
    div {
        text-align: center;
        padding: 0px 0 5px;

        @media (min-width: 768px) {
            border-right: 1px solid #eaeaea;
        }
    }
    .no-border {
        border-right: 0px;
    }


}

.server-info {
    .info-title {
        @extend .h5, .font-weight-bold, .text-muted;
    }
    
    .info-text {
        @extend .font-weight-light, .mb-0;
        font-size: 2.7em;
    }
}


.server-info.information {

    .content {
        margin-top: 15px;
        margin-bottom: 15px;
        p > img {
            max-width: 100%;
        }
        p, h1, h2, h3, h4, h5, h6 {
            @extend .px-1
        }
    }
    .content.youtube {
        margin-top: 0;
        padding: 0px 0px 10px 0px;
    }
    .content.ad {
        text-align: center;
        padding: 15px 0 15px 0;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,92d050+52,ffffff+100&1+0,0.4+52,1+100 */
background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(146,208,80,0.4) 52%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(146,208,80,0.4) 52%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(146,208,80,0.4) 52%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

    }
    .content.ad > .col-sm-6 {
        text-align: center;
    }
    .section-heading {
        border-left: 1px solid #92d050;
        border-bottom: 1px solid #e8e8e8;
        @extend .px-2
    }
}

.btn-naver {
    background-color: #03c75a;
    border-color: #03c75a;
}

.intercom-messenger-frame {
    z-index: 2147483648 !important;
}

@media screen and (max-width: 450px) {
    .intercom-lightweight-app .intercom-lightweight-app-launcher, #intercom-container div:has(> iframe[name="intercom-launcher-frame"]) {
        bottom: 60px;
    }
}

:lang(ko) {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        word-break: keep-all;
    }
}

a[href^="tel"]{
    color:inherit;
    text-decoration:none;
}

.venatus-250-slot-wrapper {
    height: 250px;
    line-height: 0;
}

.venatus-600-slot-wrapper {
    min-height: 250px;
    line-height: 0;
}

@media only screen and (max-width: 728px) {
    .venatus-600-slot-wrapper {
        height: 600px;
    }
}
